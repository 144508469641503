<template>
  <v-dialog
    v-model="dialog"
    max-width="620px"
  >
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-icon
              size="20"
              @click="dialog = false"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>

          <v-col align="center">
            <h5>{{ formType === 'create' ? 'Add Project' : 'Edit Project' }}</h5>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-form
        ref="form"
        @submit.prevent="submitForm"
      >
        <v-card-text class="py-8 px-8">
          <uppy-uploader
            ref="uppyRef"
            :data-index="`${Math.round(Math.random() * 100000)}`"
            :data-uppy="uppyDataProps"
            :config="uppyConfig"
            :dashboard-config="uppyDashboardConfig"
            @uppy-file="(uploadUrl) => logoUrl = uploadUrl"
          />

          <div class="d-flex align-start project-logo-container">
            <v-btn
              outlined
              class="show-uppy-modal"
              :style="isDark ? { 'border-color': isNoLogoUploaded ? 'red' : '#5E5669AD' } : { 'border-color': isNoLogoUploaded ? 'red' : 'lightgray'}"
            >
              <!-- {{ isNoLogoUploaded }} -->
              <v-img
                :src="logoUrl || 'https://png.pngtree.com/png-clipart/20200225/original/pngtree-image-edit-icon-photo-edit-sign-png-image_5279791.jpg'"
                contain
                width="40"
                height="40"
              >
              </v-img>
            </v-btn>

            <v-text-field
              v-model="currentProject.name"
              label="Project name"
              outlined
              :rules="[validateIconAndName]"
            >
            </v-text-field>
          </div>
          <v-textarea
            v-model="currentProject.address"
            label="Address"
            auto-grow
            rows="4"
            outlined
            :rules="addressRules"
          >
          </v-textarea>

          <v-select
            v-model="currentProject.channel"
            label="Select channel"
            :items="channelList"
            return-object
            outlined
            :rules="channelRules"
          >
            <template v-slot:selection="{ item }">
              <v-badge
                inline
                left
                dot
                :color="item.color"
                class="me-2"
              ></v-badge>
              {{ `${item.label} - ${item.phone_number}` }}
            </template>

            <template v-slot:item="{ item }">
              <v-badge
                inline
                left
                dot
                :color="item.color"
                class="me-2"
              ></v-badge>
              {{ `${item.label} - ${item.phone_number}` }}
            </template>
          </v-select>

          <v-text-field
            v-model="currentProject.email"
            label="Email"
            outlined
            :rules="emailRules"
          >
          </v-text-field>

          <v-text-field
            v-model="currentProject.ID_xendit_account"
            label="Xendit account ID"
            :error="xenditAccIdError"
            :error-messages="xenditAccIdErrMsg"
            outlined
          >
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-form>
        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose } from '@mdi/js'
import UppyUploader from '@/components/UppyUploader.vue'
import currenciesData from './currencies.json'

export default {
  name: 'AddProject',
  components: { UppyUploader },
  props: {
    value: { type: Boolean, required: false },
    formType: { type: String, required: false, default: 'create' },
    channelList: { type: Array, required: true, default: () => [] },
  },
  setup() {
    const { isDark } = useAppConfig()

    const currencies = Object.keys(currenciesData).map(key => currenciesData[key])

    return {
      isDark,
      icons: { mdiClose },
      currencies,
    }
  },
  data() {
    return {
      logoUrl: '',
      noLogoError: false,
      uppyDataProps: null,
      uppyConfig: {
        autoProceed: false,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: 100 * 100 * 100,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.jfif'],
        },
      },
      uppyDashboardConfig: {
        note: ' Image Only',
        theme: 'light',
        trigger: '.show-uppy-modal',
        inline: false,
        disabled: false,
        hideUploadButton: true,
      },
      xenditAccIdError: false,
      xenditAccIdErrMsg: '',
      loading: false,

      // {R U L E S} //
      isNoLogoUploaded: false,
      nameRules: [value => !!value || 'Name wajib diisi'],
      addressRules: [value => !!value || 'Alamat wajib diisi'],
      channelRules: [value => !!value || 'Channel wajib dipilih'],
      emailRules: [value => !!value || 'Email wajib diisi'],

      /// /////////////
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    instance() {
      return this.$store.getters['auth/getListInstance']
    },
    currentProject() {
      return this.$store.getters['manageProject/getCurrentProject']
    },
  },
  watch: {
    formType(val) {
      if (val === 'create') {
        this.resetInput()
      }

      this.noLogoError = false
      this.xenditAccIdError = false
      this.xenditAccIdErrMsg = ''
      if (val === 'edit') {
           this.logoUrl = this.currentProject.logo.uploadURL
        //console.log('masuk di watch formtype edit')
      }
    },
    // eslint-disable-next-line object-shorthand
    'currentProject.logo.uploadURL'() {
      if (this.formType === 'edit') {
        //console.log('masuk watchhhhhhhhhhhh')
        this.logoUrl = this.currentProject.logo.uploadURL
        this.uppyDataProps = { attachments: [this.currentProject.logo] }
        this.uppyDashboardConfig.disabled = false
      }
    },
    currentProject() {
      //console.log(this.formType)
      if (this.formType === 'edit') {
        //console.log(666666)
        this.logoUrl = this.currentProject.logo.uploadURL
      }
    },
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate()
      const inputData = {
        logo: this.uploadedImage(),
        name: this.currentProject.name,
        currency: this.currentProject.currency,
        address: this.currentProject.address,
        channel: this.currentProject.channel,
        phone_number: this.currentProject.phone_number,
        email: this.currentProject.email,
        ID_xendit_account: this.currentProject.ID_xendit_account,
      }

      if (!this.currentProject.ID_xendit_account) {
        this.xenditAccIdErrMsg = ''
        this.xenditAccIdError = true

        return
      }

      this.loading = true
      if (this.formType === 'create') {
        const response = await this.$store.dispatch('manageProject/addProject', {
          sub_id: this.user.sub_id,
          input_data: inputData,
        })

        if (response.status) {
          this.$store.commit('manageSalary/setCurrentProject', response.data)
          this.loading = false
        }

        this.mapErrorResponse(response)

        if (!response.status) {
          this.loading = false

          return
        }
      } else if (this.formType === 'edit') {
        const response = await this.$store.dispatch('manageProject/editProject', {
          sub_id: this.user.sub_id,
          input_data: inputData,
          id: this.currentProject.ID,
        })
        //console.log(response)

        this.mapErrorResponse(response)

        if (!response.status) {
          this.loading = false

          return
        }

        this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
      }

      this.loading = false
      this.dialog = false
      this.resetInput()
    },
    uploadedImage() {
      return this.$refs.uppyRef.uppy.getFiles()[0]
    },
    mapErrorResponse(response) {
      if (response.message === 'INVALID_API_KEY') {
        this.xenditAccIdErrMsg = 'Invalid Xendit Account ID'
        this.xenditAccIdError = true
      }

      if (response.message === 'no logo data') {
        this.noLogoError = true
      } else if (response.message !== 'no logo data') {
        this.noLogoError = false
      }
    },
    resetInput() {
      this.$store.commit('manageProject/setCurrentProject', {
        logo: {},
        name: '',
        address: '',
        phone_number: '',
        email: '',
        ID_xendit_account: '',
      })
      this.logoUrl = ''
      this.uppyDataProps = null
    },
    validateIconAndName(value) {
      if (!value && !this.logoUrl) {
        this.isNoLogoUploaded = true

        return 'Logo dan nama wajib diisi.'
      }
      if (!value) {
        return 'Nama wajib diisi.'
      }
      if (!this.logoUrl) {
        this.isNoLogoUploaded = true

        return 'Logo wajib diisi.'
      }

      this.isNoLogoUploaded = false

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.project-logo-container {
  gap: 1rem;
}

.v-btn.show-uppy-modal {
  min-width: 56px !important;
  width: 56px !important;
  height: 56px !important;
}
</style>

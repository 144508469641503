var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',{staticClass:"mb-10"},[_vm._v(" Manage Project ")]),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.showAddProjectModal = true; _vm.formType = 'create'}}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Add Project ")],1),_c('add-project-modal',{attrs:{"form-type":_vm.formType,"channel-list":_vm.channelList},model:{value:(_vm.showAddProjectModal),callback:function ($$v) {_vm.showAddProjectModal=$$v},expression:"showAddProjectModal"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search project","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projects,"search":_vm.search,"item-key":"ID"},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"border":"1px solid lightgrey","border-radius":"3px"},attrs:{"src":item.logo.uploadURL,"width":"42","height":"42","contain":""}})]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.phone_number)+" ")]),_c('div',[_vm._v(_vm._s(item.email))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toEditProject(item.ID)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.ID)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.toEditProject(item.ID)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.ID)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)]}}])}),_c('confirm-delete-modal',{attrs:{"item-id":_vm.itemId,"loading":_vm.deleteLoading},on:{"handle-delete":_vm.deleteProject},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}},[_vm._v(" Are you sure you want to delete this project? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
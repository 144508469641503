<template>
  <v-container>
    <!-- Header -->
    <h3 class="mb-10">
      Manage Project
    </h3>

    <!-- Toolbar -->
    <v-row class="mb-6">
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click="showAddProjectModal = true; formType = 'create'"
        >
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Project
        </v-btn>
        <add-project-modal
          v-model="showAddProjectModal"
          :form-type="formType"
          :channel-list="channelList"
        />
      </v-col>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search project"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="projects"
      :search="search"
      item-key="ID"
      class="elevation-1"
    >
      <template v-slot:item.logo="{ item }">
        <v-img
          :src="item.logo.uploadURL"
          width="42"
          height="42"
          contain
          style="border: 1px solid lightgrey; border-radius: 3px;"
        ></v-img>
      </template>

      <template v-slot:item.contact="{ item }">
        <div class="d-flex flex-column">
          <div class="font-weight-bold">
            {{ item.phone_number }}
          </div>
          <div>{{ item.email }}</div>
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="action-btn me-4"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toEditProject(item.ID)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="confirmDeletion(item.ID)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon
              color="primary"
              @click="toEditProject(item.ID)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="confirmDeletion(item.ID)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <confirm-delete-modal
      v-model="showDeleteConfirmationModal"
      :item-id="itemId"
      :loading="deleteLoading"
      @handle-delete="deleteProject"
    >
      Are you sure you want to delete this project?
    </confirm-delete-modal>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
} from '@mdi/js'

import AddProjectModal from './AddProject.vue'
import ConfirmDeleteModal from '../shared/ConfirmDelete.vue'

export default {
  name: 'ManageProject',
  components: { AddProjectModal, ConfirmDeleteModal },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
      },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Logo',
          align: 'start',
          value: 'logo',
        },
        { text: 'Name', value: 'name' },
        { text: 'Address', value: 'address', width: '30%' },
        { text: 'Contact', value: 'contact' },
        { text: 'Action', align: 'end', value: 'action' },
      ],
      showAddProjectModal: false,
      formType: '',
      channelList: [],
      itemId: null,
      showDeleteConfirmationModal: false,
      deleteLoading: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      if (this.searchText) {
        return
      }

      return this.$store.getters['manageProject/getProjects']
    },
  },
  async created() {
    this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
    const {
      data: { value: channelList },
    } = await this.$store.dispatch('global/getSettingsByKey', {
      sub_id: this.user.sub_id,
      token: this.user.token,
      key: 'instances',
    })

    this.channelList = channelList
  },
  methods: {
    async toEditProject(projectId) {
      await this.$store.dispatch('manageProject/getProjectById', {
        sub_id: this.user.sub_id,
        id: projectId,
      })

      this.formType = 'edit'
      this.showAddProjectModal = true
    },
    confirmDeletion(itemId) {
      this.itemId = itemId
      this.showDeleteConfirmationModal = true
    },
    async deleteProject(projectId) {
      this.deleteLoading = true
      await this.$store.dispatch('manageProject/deleteProject', {
        sub_id: this.user.sub_id,
        id: projectId,
      })
      this.deleteLoading = false
      this.showDeleteConfirmationModal = false

      const projects = await this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
      this.$store.commit('manageSalary/setCurrentProject', projects[0])
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
